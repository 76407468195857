import React, { useState, useEffect } from 'react';
import './mainpage.css';
import Market from './../../components/Market';

function MainPage() {
  const [activeTable, setActiveTable] = useState(0); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 802); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 802);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTableSwitch = (index) => {
    setActiveTable(index);
  };

  return (
    <main className='main'>
      <div className='container'>
        <div className='main_content'>
          <span className='main_textcheats'>
            Cheats for moneymaking
          </span>
  
          {isMobile ? (
            <div className='mobile_version'>
              <div className='mobile_buttons'>
                <button 
                  className={`mobile_buttons_element ${activeTable === 0 ? 'mobile_buttons_element_active' : ''}`} 
                  onClick={() => handleTableSwitch(0)}
                >
                  Chart 1
                </button>
                <button 
                  className={`mobile_buttons_element ${activeTable === 1 ? 'mobile_buttons_element_active' : ''}`} 
                  onClick={() => handleTableSwitch(1)}
                >
                  Chart 2
                </button>
                <button 
                  className={`mobile_buttons_element ${activeTable === 2 ? 'mobile_buttons_element_active' : ''}`} 
                  onClick={() => handleTableSwitch(2)}
                >
                  Chart 3
                </button>
              </div>
              <Market 
                key={activeTable} 
                currency={activeTable === 0 ? 'BTC' : activeTable === 1 ? 'ETH' : 'ZOC'} 
                secondCurrency="USDT" 
              />
            </div>
          ) : (
            <div className='tables_group'>
              <Market currency="BTC" secondCurrency="USDT" />
              <Market currency="ETH" secondCurrency="USDT" />
              <Market currency="ZOC" secondCurrency="USDT" />
            </div>
          )}
        </div>
      </div>
    </main>
  );
  
}

export default MainPage;
