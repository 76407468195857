import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import coinsData from '../coins.json';
import Icon from './../img/iconcrypt.svg';
import './../pages/MainPage/mainpage.css';
import './components.css';
import Arrow from './../img/market_arrow_up.svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Market({ currency, secondCurrency }) {
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [selectedCurrencyPropSecond, setSelectedCurrencyPropSecond] = useState(secondCurrency);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCoins, setFilteredCoins] = useState(coinsData);
  const [favorites, setFavorites] = useState([]);
  const [prices, setPrices] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [sortType, setSortType] = useState({ field: 'price', order: 'desc' });

  const currencySelectorRef = useRef(null);
  const currencySecondSelectorRef = useRef(null);

  const stablecoins = ['USDT', 'BUSD', 'USDC', 'DAI'];

  const toggleDropdown = (type) => {
    setDropdownOpen(dropdownOpen === type ? null : type);
  };

  const handleCurrencySelect = (currency, type) => {
    if (type === 'first') {
      setSelectedCurrency(currency);
    } else {
      setSelectedCurrencyPropSecond(currency);
    }
    setDropdownOpen(null);
  };

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    setFilteredCoins(coinsData.filter(coin =>
      coin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      coin.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [searchTerm]);

  useEffect(() => {
    setNoResults(false);
    fetchPrices();
  }, [selectedCurrency, selectedCurrencyPropSecond]);

  useEffect(() => setSelectedCurrency(currency), [currency]);
  useEffect(() => setSelectedCurrencyPropSecond(secondCurrency), [secondCurrency]);

  const fetchPrices = async () => {
    const baseCurrency = coinsData.find(coin => coin.symbol.toUpperCase() === selectedCurrency);
    if (!baseCurrency) return;

    setPrices([]);

    try {
      if (stablecoins.includes(selectedCurrencyPropSecond)) {
        const response = await fetch(`/api/coins/${baseCurrency.id}`);
        const data = await response.json();
        const filteredPrices = data.tickers.filter(ticker => ticker.target === selectedCurrencyPropSecond);

        setPrices(filteredPrices.length > 0 ? filteredPrices : []);
        setNoResults(filteredPrices.length === 0);
      } else {
        const secondCurrencyObj = coinsData.find(coin => coin.symbol.toUpperCase() === selectedCurrencyPropSecond);
        if (!secondCurrencyObj) return;

        const [firstCurrencyData, secondCurrencyData] = await Promise.all([
          fetch(`/api/coins/${baseCurrency.id}`).then(response => response.json()),
          fetch(`/api/coins/${secondCurrencyObj.id}`).then(response => response.json())
        ]);

        let calculatedPrices = [];

        const firstCurrencyMarkets = firstCurrencyData.tickers.filter(ticker => stablecoins.includes(ticker.target));
        const secondCurrencyMarkets = secondCurrencyData.tickers.filter(ticker => stablecoins.includes(ticker.target));
        
        calculatedPrices = firstCurrencyMarkets.map(firstTicker => {
          const secondTicker = secondCurrencyMarkets.find(secondTicker => secondTicker.market.name === firstTicker.market.name);
          
          if (secondTicker) {
            return {
              ...firstTicker,
              last:  secondTicker.last / firstTicker.last, 
              trade_url: modifyTradeUrl(firstTicker.trade_url, selectedCurrencyPropSecond)
            };
          }
          return null;
        }).filter(Boolean);

        setPrices(calculatedPrices.length > 0 ? calculatedPrices : []);
        setNoResults(calculatedPrices.length === 0);
      }
    } catch (error) {
      console.error(`Error fetching prices:`, error);
      setNoResults(true);
    }
  };

  const toggleFavorite = (coin, e) => {
    e.stopPropagation();
    const updatedFavorites = favorites.includes(coin.symbol)
      ? favorites.filter(fav => fav !== coin.symbol)
      : [...favorites, coin.symbol];
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  const sortPrices = (field) => {
    const isAscending = sortType.field === field && sortType.order === 'asc';
    const sortedPrices = [...prices].sort((a, b) => {
      if (field === 'price') {
        return isAscending ? a.last - b.last : b.last - a.last;
      }
      return isAscending ? a.market.name.localeCompare(b.market.name) : b.market.name.localeCompare(a.market.name);
    });
    setPrices(sortedPrices);
    setSortType({ field, order: isAscending ? 'desc' : 'asc' });
  };

  const handleClickOutside = (event) => {
    if (
      (currencySelectorRef.current && !currencySelectorRef.current.contains(event.target)) &&
      (currencySecondSelectorRef.current && !currencySecondSelectorRef.current.contains(event.target))
    ) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderDropdown = (type, selectedValue, ref) => (
    <div className='currency_selector' ref={ref} onClick={() => toggleDropdown(type)}>
      {selectedValue}
      {dropdownOpen === type && (
        <div className='currency_dropdown_wrapper'>
          <div className='currency_dropdown' onClick={(e) => e.stopPropagation()}>
            <input
              type="text"
              placeholder="Search"
              className='currency_search_input'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='currency_dropdown_list'>
              <div className="favorites_section">
                {filteredCoins
                  .filter(coin => favorites.includes(coin.symbol))
                  .map((coin) => (
                    <div
                      key={coin.id}
                      className='currency_option'
                      onClick={() => handleCurrencySelect(coin.symbol.toUpperCase(), type)}
                    >
                      <button
                        className='favorite_button'
                        onClick={(e) => toggleFavorite(coin, e)}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          fill={favorites.includes(coin.symbol) ? "#D91528" : "#000000"}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 3.9855C0 2.16432 1.44329 0.666626 3.24968 0.666626C4.19314 0.666626 5.0112 1.16566 5.57837 1.84598C5.73636 2.03549 5.87787 2.24234 5.99981 2.46171C6.12165 2.24239 6.26303 2.03559 6.4209 1.8461C6.98776 1.16571 7.80554 0.666626 8.74905 0.666626C10.5554 0.666626 11.9987 2.16432 11.9987 3.9855C11.9987 5.60037 10.9929 7.03226 9.84349 8.21059C8.96233 9.11392 7.92722 9.93464 7.06314 10.6197C6.79444 10.8328 6.54229 11.0327 6.31644 11.2179C6.13209 11.3691 5.86664 11.3691 5.6823 11.2179C5.45645 11.0327 5.20429 10.8328 4.93559 10.6197C4.07152 9.93464 3.03641 9.11392 2.15524 8.21059C1.00584 7.03226 0 5.60037 0 3.9855ZM6.5 4.33329H5.5C5.5 3.6806 5.23888 3.00044 4.81027 2.48632C4.38193 1.97251 3.82484 1.66663 3.24968 1.66663C2.01886 1.66663 1 2.69304 1 3.9855C1 5.18951 1.76181 6.37514 2.87108 7.51232C3.70293 8.3651 4.66346 9.12695 5.51944 9.80587C5.68382 9.93625 5.84435 10.0636 5.99937 10.1879C6.15439 10.0636 6.31491 9.93625 6.4793 9.80587C7.33528 9.12695 8.2958 8.36511 9.12766 7.51232C10.2369 6.37514 10.9987 5.18951 10.9987 3.9855C10.9987 2.69304 9.97988 1.66663 8.74905 1.66663C8.17395 1.66663 7.61721 1.97246 7.1892 2.4862C6.7609 3.00027 6.5 3.68046 6.5 4.33329Z"
                          />
                        </svg>
                      </button>
                      <span>
                        {coin.name} ({coin.symbol.toUpperCase()})
                      </span>
                    </div>
                  ))}
              </div>
              <div className="all_coins_section">
                {filteredCoins
                  .filter(coin => !favorites.includes(coin.symbol))
                  .map((coin) => (
                    <div
                      key={coin.id}
                      className='currency_option'
                      onClick={() => handleCurrencySelect(coin.symbol.toUpperCase(), type)}
                    >
                      <button
                        className='favorite_button'
                        onClick={(e) => toggleFavorite(coin, e)}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          fill={favorites.includes(coin.symbol) ? "#D91528" : "#000000"}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 3.9855C0 2.16432 1.44329 0.666626 3.24968 0.666626C4.19314 0.666626 5.0112 1.16566 5.57837 1.84598C5.73636 2.03549 5.87787 2.24234 5.99981 2.46171C6.12165 2.24239 6.26303 2.03559 6.4209 1.8461C6.98776 1.16571 7.80554 0.666626 8.74905 0.666626C10.5554 0.666626 11.9987 2.16432 11.9987 3.9855C11.9987 5.60037 10.9929 7.03226 9.84349 8.21059C8.96233 9.11392 7.92722 9.93464 7.06314 10.6197C6.79444 10.8328 6.54229 11.0327 6.31644 11.2179C6.13209 11.3691 5.86664 11.3691 5.6823 11.2179C5.45645 11.0327 5.20429 10.8328 4.93559 10.6197C4.07152 9.93464 3.03641 9.11392 2.15524 8.21059C1.00584 7.03226 0 5.60037 0 3.9855ZM6.5 4.33329H5.5C5.5 3.6806 5.23888 3.00044 4.81027 2.48632C4.38193 1.97251 3.82484 1.66663 3.24968 1.66663C2.01886 1.66663 1 2.69304 1 3.9855C1 5.18951 1.76181 6.37514 2.87108 7.51232C3.70293 8.3651 4.66346 9.12695 5.51944 9.80587C5.68382 9.93625 5.84435 10.0636 5.99937 10.1879C6.15439 10.0636 6.31491 9.93625 6.4793 9.80587C7.33528 9.12695 8.2958 8.36511 9.12766 7.51232C10.2369 6.37514 10.9987 5.18951 10.9987 3.9855C10.9987 2.69304 9.97988 1.66663 8.74905 1.66663C8.17395 1.66663 7.61721 1.97246 7.1892 2.4862C6.7609 3.00027 6.5 3.68046 6.5 4.33329Z"
                          />
                        </svg>
                      </button>
                      <span>
                        {coin.name} ({coin.symbol.toUpperCase()})
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const modifyTradeUrl = (url, secondCurrency) => {
    if (!url) {
      return '#';  
    }

    let newUrl = url;
    
    stablecoins.forEach(stablecoin => {
      newUrl = newUrl.replace(stablecoin, secondCurrency);
    });
    
    return newUrl;
  };
  return (
    <div className='table_container'>
      <div className='currency-selector_container'>
        {renderDropdown('first', selectedCurrency, currencySelectorRef)}
        <span className='currency_devaider'>/</span>
        {renderDropdown('second', selectedCurrencyPropSecond, currencySecondSelectorRef)}
      </div>

      <div className='main_marketborder'>
        {noResults ? (
          <div className="no-results main_market">
            <p className='no-results_text'>No currency pairs found</p>
            <button className='no-results_button' onClick={() => setDropdownOpen('first')}>Try other currency pairs</button>
          </div>
        ) : (
          <div className='main_market'>
            <div className='market_filters'>
              <span 
                className={`filters_text market_text group_one ${sortType.field === 'market' ? (sortType.order === 'asc' ? 'sort_from_less' : 'sort_from_more') : ''}`} 
                onClick={() => sortPrices('market')}
              >
                Market
                <img className='filters_arrow' src={Arrow} alt='filter'/>
              </span>
              <span 
                className={`filters_text group_two ${sortType.field === 'price' ? (sortType.order === 'asc' ? 'sort_from_less' : 'sort_from_more') : ''}`} 
                onClick={() => sortPrices('price')}
              >
                Price
                <img className='filters_arrow' src={Arrow} alt='filter'/>
              </span>
            </div>
            {prices.length === 0 ? (
              Array(16).fill().map((_, index) => (
                <div key={index} className='main_market_element'>
                  <span className='crypt_name group_one'>
                    <Skeleton width={100} />
                  </span>
                  <span className='crypt_buy group_two'>
                    <Skeleton width={50} />
                  </span>
                  <Skeleton width={40} height={20} />
                </div>
              ))
            ) : (
              prices.map((ticker, index) => (
                <div key={index}>
                  <div className='main_market_element'>
                    <span className='crypt_name group_one'>
                      <img className='market_icon' src={Icon} alt='icon'/>
                      {ticker.market.name}
                    </span>
                    <span className='crypt_buy group_two'>
                      {ticker.last.toFixed(2)}
                    </span>
                    <Link to={modifyTradeUrl(ticker.trade_url, selectedCurrencyPropSecond)} className='market_button' target='_blank'>
                      Go
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Market;
