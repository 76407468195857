import './components.css'
import Logo from "./../img/logo-frame.svg"
import { Link } from 'react-router-dom';

function Header() {
    return (
      <header className='header'>
        <div className='container'>
          <Link className='header_content' to="/">
            <img className='header_img' src={Logo}/>
            <div className='header_group'>
              <span className='header_bigtext'>
                MarketVisor.online
              </span>
              <span className='header_text'>
                All markets, all coins, all rates...
              </span>
            </div>
          </Link>
        </div>
      </header>
    );
}
  
export default Header;