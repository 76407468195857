import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainPage from './pages/MainPage/MainPage';
import Policy from './pages/Privacy/Policy';
import Cookies from './pages/Privacy/Cookies';
import Terms from './pages/Privacy/Terms';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
